<script setup lang="ts">
import {useUserProfileStore} from "~/store/user/user_prifile.store";
import {$useApiFetch} from "~/composables/useApiFetch";
import type {UserResponse} from "~/common/interfaces";
import {endpoints} from "~/constants/endpoints";
import {useIsLoadingStore} from "~/store/loading.store";

const userProfile = useUserProfileStore();
const Loader = useIsLoadingStore();

const route = useRoute()

const RoleSelection = computed(() => {
  if (userProfile.Role !== 'CUSTOMER') {
    return 'CUSTOMER'
  } else {
    return 'MASTER'
  }
})


async function UserRoleSelector() {
  try {
    Loader.set(true);
    await $useApiFetch<UserResponse>(endpoints.user_management.change_role, {
      method: 'POST',
      body: JSON.stringify({
        role: RoleSelection.value
      })
    })
  } catch (e) {
    console.log(e)
  } finally {
    userProfile.Role = RoleSelection.value
    if (route.path.includes('office')) {
      navigateTo(`/${userProfile.Role.toLowerCase()}-office/`);
    } else {
      navigateTo('/');
    }
    setTimeout(() => {
      Loader.set(false);
    }, 600)
  }
}


</script>

<template>
  <UiSwitch 
    :default-checked="userProfile.Role === 'MASTER'"
    @click="UserRoleSelector" 
    class="data-[state=checked]:bg-primary-500"
    />
</template>