<script lang="ts" setup>
import {Role} from "~/types/role";

const {t} = useI18n()
const route = useRoute();
const userStore = useUserProfileStore()
const authStore = useAuthStore()
const statesStore = useStatesStore();

const props = defineProps({
    class: {
        type: String,
    }
})

const open = ref(false)
const linkPrefix = computed(() => {
    switch (userStore.Role) {
        case Role.MASTER:
            return '/master-office';
        default:
            return '/customer-office'
    }
})
const currentRole = computed(() => userStore.Role)

const menus = computed(() => {
    return [
        {
            title: t('Profile Settings'),
            items: [
                {
                    name: t('Profile Settings'),
                    link: linkPrefix.value + '/profile',
                    icon: 'ri:user-line',
                    role: [Role.MASTER, Role.CUSTOMER]
                },
                {
                    name: t('Portfolio'),
                    link: linkPrefix.value + '/portfolio',
                    icon: 'ri:grid-line',
                    role: [Role.MASTER]
                },
            ]
        },
        {
            title: t('App'),
            items: [
                {
                    name: t('Notification Settings'),
                    link: linkPrefix.value + '/profile/notification-settings',
                    icon: 'ri:notification-badge-line',
                    role: [Role.MASTER, Role.CUSTOMER]
                },
                {
                    name: t('Privacy & Security'),
                    link: linkPrefix.value + '/profile/privacy-security',
                    icon: 'ri:lock-line',
                    role: [Role.MASTER, Role.CUSTOMER]
                },
            ]
        }
    ]
})

function logout() {
    open.value = false;
    authStore.clear();
    statesStore.useClearCustomStates();
    userStore.useClearUserProfile();
    navigateTo('/');
}

watch(
    () => route.path,
    () => {
        open.value = false
    },
);

</script>

<template>
    <UiDialog v-model:open="open">
        <UiDialogTrigger>
            <slot/>
        </UiDialogTrigger>
        <UiDialogContent class="ys-modal-dialog w-full gap-0" :class="class">
            <UiDialogHeader class="px-4 py-5 mb-6">

                <p class="mb-1 text-center uppercase text-[13px] tracking-widest">{{ $t('Profile') }}</p>

                <UiDialogClose class="absolute top-4 ltr:right-4 rtl:left-4">
                    <Icon name="ri:close-line" size="20px" class="text-gray-500 translate-all hover:text-gray-700"/>
                </UiDialogClose>
            </UiDialogHeader>

            <div class="px-5 grid gap-10">
                <div v-for="section in menus" class="ys-second-menu-tabs">
                    <p class="font-bold text-lg">{{ section.title }}</p>
                    <nav class="mt-4">
                        <template v-for="item in section.items">
                            <NuxtLink
                                v-if="item.role.includes(currentRole)"
                                :to="item.link"
                                class="flex justify-start items-center my-1 py-2.5"
                                :class="{
                                    'text-primary-500': route.path === item.link
                                 }">
                                <Icon size="24" class="menu-item-icon ltr:mr-3 rtl:ml-3" :name="item.icon"/>
                                {{ item.name }}
                                <Icon size="18" class="ltr:ml-auto rtl:mr-auto rtl:rotate-180"
                                      name="ri:arrow-right-s-line"/>
                            </NuxtLink>
                        </template>
                        <!-- <NuxtLink to="/" class="ys-second-menu-tabs-link flex justify-start my-1"
                          value="Contact Information">
                          <Icon size="18" class="menu-item-icon ltr:mr-1 rtl:ml-1" name="ri:contacts-book-line" />
                          {{ $t("Contact Information") }}
                          <Icon size="18" class="ltr:ml-auto rtl:mr-auto rtl:rotate-180 hidden-icon"
                            name="ri:arrow-right-s-line" />
                        </NuxtLink>
                        <NuxtLink to="/" class="ys-second-menu-tabs-link flex justify-start my-1" value="Role">
                          <Icon size="18" class="menu-item-icon ltr:mr-1 rtl:ml-1" name="ri:user-settings-line" />
                          {{ $t("Role") }}
                          <Icon size="18" class="ltr:ml-auto rtl:mr-auto rtl:rotate-180 hidden-icon"
                            name="ri:arrow-right-s-line" />
                        </NuxtLink>
                        <NuxtLink to="/" class="ys-second-menu-tabs-link flex justify-start my-1"
                          value="Regional Preferences">
                          <Icon size="18" class="menu-item-icon ltr:mr-1 rtl:ml-1" name="ri:global-line" />
                          {{ $t("Regional Preferences") }}
                          <Icon size="18" class="ltr:ml-auto rtl:mr-auto rtl:rotate-180 hidden-icon"
                            name="ri:arrow-right-s-line" />
                        </NuxtLink> -->
                    </nav>
                </div>
            </div>

            <div class="mt-auto flex justify-center mb-6">
                <UiButton @click="logout" variant="link" class="text-gray-400">{{ $t('Log Out') }}</UiButton>
            </div>
        </UiDialogContent>
    </UiDialog>
</template>

<style scoped lang="scss">
.ys-second-menu-tabs-link {
    padding: 8px;
    gap: 6px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: rgba(82, 88, 102, 1);
    @media(max-width: 860px) {
        width: 100%;
    }

    .hidden-icon {
        display: none;
        width: 20px;
        height: 20px;
        gap: 8px;
        border-radius: 24px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 2px 4px 0 rgba(27, 28, 29, 0.04);
    }

    &:hover {
        color: rgba(10, 13, 20, 1);
        background: rgba(246, 248, 250, 1);
    }
}


[data-state="active"] {
    color: rgba(10, 13, 20, 1);
    background: rgba(246, 248, 250, 1);

    .hidden-icon {
        display: block;
    }

    .menu-item-icon {
        color: rgba(45, 159, 117, 1);
    }
}

</style>