<script lang="ts" setup>
import type { TabItem } from '~/store/user/user_prifile.store';
import { Role } from '~/types/role';

const userStore = useUserProfileStore()
const authStore = useAuthStore()

const route = useRoute()
const newMessagesCount = computed(() => userStore.getNewMessagesCount())


const tabList = userStore.navTabs.filter(item => item.role === userStore.Role && item.show_in_mobile)
const isCurrentRoute = (item: TabItem) => {
	return route.path === item.path || item.child_pages.includes(route.path)
}

</script>

<template>
	<Teleport to="#teleports">
		<div v-if="authStore.isAuth && userStore.Role"
			class="grid md:hidden fixed bottom-0 left-0 right-0 bg-white shadow-[0_0_5px_0_rgba(0,0,0,0.07)] z-50" :class="{
				'grid-cols-3': tabList.length === 3,
				'grid-cols-4': tabList.length === 4,
				'grid-cols-5': tabList.length === 5,
			}">
			<template v-for="item in tabList">
				<CustomUiProfileMenuModal v-if="item.name === 'My profile'">
					<UiButton
						class="p-2 flex-col h-auto items-center bg-white text-gray-500 rounded-md hover:bg-stroke relative"
						:class="{ 'text-primary-500': isCurrentRoute(item) }">
							<Icon v-if="item.icon" :name="item.icon" size="24px" class="mb-1" />
							<span class="text-[10px] sm:text-xs">{{ $t(item.name) }}</span>
					</UiButton>
				</CustomUiProfileMenuModal>
				
				<UiButton
					v-else
					class="p-2 flex-col h-auto items-center bg-white text-gray-500 rounded-md hover:bg-stroke relative"
					:class="{ 'text-primary-500': isCurrentRoute(item) }" as-child>
					<NuxtLink :to="item.path">
						<span v-if="newMessagesCount && item.name === 'Chats'"
							class="absolute top-2 left-[50%] badge bg-red-500 text-[10px] sm:text-xs leading-none text-white rounded-full py-0.5 px-1">{{
								newMessagesCount }}</span>
						<Icon v-if="item.icon" :name="item.icon" size="24px" class="mb-1" />
						<span class="text-[10px] sm:text-xs">{{ $t(item.name) }}</span>
					</NuxtLink>
				</UiButton>
			</template>
		</div>
	</Teleport>
</template>



<style></style>