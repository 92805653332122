<script setup lang="ts">
const {locales, locale, setLocale} = useI18n(); // Импорт функций и переменных из i18n для работы с локализацией
import {ref} from "vue"; // Импорт функции ref для создания реактивных данных
import {onClickOutside} from '@vueuse/core'; // Импорт функции для обработки кликов вне элемента

const open = ref(false); // Реактивное состояние для отслеживания, открыто ли выпадающее меню
const target = ref(null); // Реактивная ссылка на целевой элемент, для которого отслеживаются клики вне его области

// Функция закрытия выпадающего меню
function close() {
  open.value = false;
}

// Вычисляемое свойство для получения текущего выбранного языка
const currentLanguage = computed(() => {
  return locales.value.find(item => item.code === locale.value);
})

// Обработчик клика вне элемента для закрытия выпадающего меню
onClickOutside(target, close);
</script>

<template>
  <div class="relative inline-block my-auto" ref="target">
    <!-- Кнопка, открывающая выпадающее меню -->
    <UiButton @click="open = !open" type="button"
              :class="{custom: open}"
              class="lg-selector-button"
              id="menu-button" aria-expanded="true" aria-haspopup="true">
      <!-- Иконка глобуса и название текущего языка -->
      <Icon size="18" :class="{custom_icon: open}" class="sm:mr-1" name="ri:global-line"/>
      <span class="hidden sm:inline">{{ currentLanguage?.name }}</span>
      <Icon size="18" name="ri:arrow-down-s-line"/>
    </UiButton>
    <!-- Выпадающее меню для выбора языка -->
    <div v-if="open" @click="open = false"
         class="z-10 lg-selector-body origin-top-right absolute right-0 mt-2 sm:w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
         role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"
         style="border-radius: 16px; border: 0">
      <div class="py-0.5 px-2"
           v-for="item in locales"
           :key="item.iso">
        <div class="locales_list-container">
          <!-- Элемент списка языков -->
          <p class="locales_list text-gray-700 block px-4 py-2 text-sm cursor-pointer"
             :class="{current_locales: open}" role="menuitem" tabindex="-1"
             @click='setLocale(item.code)'>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.locales_list-container {
  @apply px-0;
  transition: padding 0.5s ease;
}

.locales_list-container:hover {
  border-radius: 8px;
  background: rgba(246, 248, 250, 1);
  transition: 0.5s ease;
  @apply pl-1;
}


.lg-selector-body {
  width: 128px;
  box-shadow: 0 16px 40px -8px rgba(88, 92, 95, 0.16);
}

.custom {
  color: black !important;
}

.custom_icon {
  color: rgba(45, 159, 117, 1);
}

.lg-selector-button {
  border: 0;
  color: rgba(82, 88, 102, 1);
  background: unset;
  text-decoration: unset;
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  min-width: 128px;
  padding: 8px 12px 8px 12px;
  gap: 8px;

  @media(max-width: 768px){
    min-width: 64px;
  }
}

.lg-selector-button:hover {
  background: rgba(246, 248, 250, 1);

}

</style>