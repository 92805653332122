<script setup lang="ts">
import { ref } from "vue"; // Импорт функции ref для создания реактивных данных
import { onClickOutside } from '@vueuse/core'; // Импорт функции для обработки кликов вне элемента
import { useUserProfileStore } from "~/store/user/user_prifile.store"; // Импорт хранилища профиля пользователя

// Получение состояний из Pinia stores
const userProfile = useUserProfileStore(); // Хранилище профиля пользователя
const authStore = useAuthStore(); // Хранилище для авторизации пользователя
const statesStore = useStatesStore(); // Хранилище для управления состояниями

const open = ref(false); // Реактивное состояние для отслеживания, открыто ли выпадающее меню
const target = ref(null); // Реактивная ссылка на целевой элемент, для которого отслеживаются клики вне его области

// Функция закрытия выпадающего меню
function close() {
    open.value = false;
}

// Функция выхода из аккаунта
function logout() {
    open.value = false; // Закрытие выпадающего меню
    authStore.clear(); // Очистка данных авторизации
    statesStore.useClearCustomStates(); // Очистка пользовательских состояний
    // userStatesStore.useClearUserStates(); // (Закомментировано) Очистка других пользовательских состояний
    userProfile.useClearUserProfile(); // Очистка профиля пользователя
    navigateTo('/'); // Перенаправление на главную страницу
}

// Обработчик клика вне элемента для закрытия выпадающего меню
onClickOutside(target, close);

// Настройка i18n для работы с локализацией
const { locales, locale, setLocale } = useI18n(); // Получение списка локалей, текущей локали и функции для изменения локали
const currentLanguage = computed(() => {
    return locales.value.find(item => item.code === locale.value); // Вычисление текущего выбранного языка
});
</script>

<template>
    <div class="relative inline-block my-auto" ref="target">
        <!-- Кнопка, открывающая выпадающее меню -->
        <UiButton @click="open = !open" type="button" :class="{ custom: open }" class="lg-selector-button relative"
            id="menu-button" aria-expanded="true" aria-haspopup="true">
            <!-- Аватар пользователя -->
            <CustomUiUserAvatar :name="userProfile.FullName" :src="userProfile.ImageUrl" size="2xs" />
            <!-- Имя пользователя -->
            <p class="hidden sm:block text-name">{{ userProfile.FullName || userProfile.Email }}</p>
            <!-- Иконка стрелки -->
            <Icon size="15" class="mr-1" name="ri:arrow-down-s-fill" />
            <!-- Метка роли пользователя -->
            <div v-if="userProfile.Role == 'MASTER'"
                class="absolute ltr:right-0 rtl:left-0 top-[-10px] flex justify-between items-center rounded-2xl  bg-blue-200 px-2 py-1 leading-none">
                <p class="text-blue-950 text-[10px]">{{ $t("specialist") }}</p>
            </div>
            <div v-if="userProfile.Role == 'CUSTOMER'"
                class="absolute ltr:right-0 rtl:left-0 top-[-10px] flex justify-between items-center rounded-2xl  bg-orange-200 px-2 py-1 leading-none">
                <p class="text-orange-950 text-[10px]">{{ $t("client") }}</p>
            </div>
        </UiButton>

        <!-- Выпадающее меню -->
        <div v-if="open" :class="{ 'left-0': currentLanguage?.code === 'ar', 'right-0': currentLanguage?.code != 'ar' }"
            class="lg-selector-body origin-top-right absolute mt-2 w-300 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
            role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1"
            style="border-radius: 16px; border: 0">
            <div class="py-0.5 px-2">
                <!-- Карточка профиля пользователя -->
                <div class="profile-logo-card flex justify-start items-center gap-2">
                    <CustomUiUserAvatar :name="userProfile.FullName" :src="userProfile.ImageUrl" size="2xs" />
                    <div class="">
                        <p class="text-name">{{ userProfile.FullName }}</p>
                        <p class="text-email">{{ userProfile.Email }}</p>
                    </div>
                </div>

                <UiSeparator class="my-1" />

                <!-- Предупреждение, если роль пользователя не выбрана -->
                <div v-if="!userProfile.Role" class="list-container-switch mt-2">
                    <UiTooltipProvider>
                        <UiTooltip>
                            <UiTooltipTrigger as-child>
                                <LayoutsNoticeWarning @click="navigateTo('/create-account'); open = false"
                                    message="You haven't chosen any role yet!" />
                            </UiTooltipTrigger>
                            <UiTooltipContent>
                                <p>{{ $t("Go to create an account") }}</p>
                            </UiTooltipContent>
                        </UiTooltip>
                    </UiTooltipProvider>
                </div>

                <!-- Ссылки на настройки профиля и другие опции для разных ролей -->
                <NuxtLink v-if="userProfile.Role == 'CUSTOMER'" @click="open = false"
                    class="list-container flex justify-start items-center my-1" to="/customer-office/profile">
                    <Icon size="18" class="mx-2 " name="ri:user-settings-line" />
                    {{ $t("Profile_Settings") }}
                </NuxtLink>
                <NuxtLink v-if="userProfile.Role == 'MASTER'" @click="open = false"
                    class="list-container flex justify-start items-center my-1" to="/master-office/profile">
                    <Icon size="18" class="mx-2 " name="ri:user-settings-line" />
                    {{ $t("Profile_Settings") }}
                </NuxtLink>
                <NuxtLink v-if="userProfile.Role == 'MASTER'" @click="open = false"
                    class="list-container flex justify-start items-center my-1" to="/master-office/portfolio">
                    <Icon size="18" class="mx-2 " name="ri:grid-line" />
                    {{ $t("Portfolio") }}
                </NuxtLink>
                <!-- <NuxtLink v-if="userProfile.Role == 'MASTER'" @click="open = false"
                    class="list-container flex justify-start items-center my-1" to="/master-office/promotion">
                    <Icon size="18" class="mx-2 " name="ri:vip-diamond-line" />
                    {{ $t("Promotion") }}
                </NuxtLink> -->

                <!-- Переключение ролей пользователя -->
                <div v-for="item in userProfile.AvailableRoles" :key="item">
                    <div class="list-container-switch flex items-center justify-start my-1" v-if="item === 'MASTER'">
                        <Icon size="18" class="mx-2" name="ri:user-received-line" />
                        <p v-if="userProfile.Role === 'CUSTOMER'">
                            {{ $t("Switch to specialist") }}
                        </p>
                        <p v-if="userProfile.Role === 'MASTER'">
                            {{ $t("Switch to client") }}
                        </p>
                        <PagesOfficeMasterModeSwitcher class="m-auto" />
                    </div>
                </div>

                <UiSeparator class="my-1" />
                <!-- Кнопка выхода из аккаунта -->
                <div class="list-container flex justify-start items-center" @click="logout">
                    <Icon size="18" class="mx-2 " name="ri:logout-box-r-line" />
                    {{ $t("Logout") }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.list-container {
    @apply px-0;
    height: 36px;
    transition: padding 0.5s ease;
    cursor: pointer;
    color: rgba(10, 13, 20, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
}

.list-container-switch {
    @apply px-0;
    height: 36px;
    transition: padding 0.5s ease;
    cursor: pointer;
    color: rgba(10, 13, 20, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.006em;
}

.list-container:hover {
    border-radius: 8px;
    background: rgba(246, 248, 250, 1);
    transition: 0.5s ease;
    @apply pl-1;
}


.lg-selector-body {
    width: 300px;
    min-height: 168px;
    padding: 8px;
    gap: 4px;
    border-radius: 20px;
    box-shadow: 0 16px 32px -12px rgba(88, 92, 95, 0.1);
}

.custom {
    color: black !important;
}

.custom_icon {
    color: rgba(45, 159, 117, 1);
}

.lg-selector-button {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-align: left;
    color: rgba(10, 13, 20, 1);
    background: unset;
    height: 40px;
    padding: 4px 8px 4px 4px;
    gap: 6px;
    border-radius: 10px;
    border: 1px solid rgba(226, 228, 233, 1);
    box-shadow: 0 1px 2px 0 rgba(228, 229, 231, 0.24);
}

.lg-selector-button:hover {
    background: rgba(246, 248, 250, 1);
}

.profile-logo-card {
    background: rgb(255, 255, 255);
    height: 56px;
    padding: 8px;
    gap: 12px;
    border-radius: 10px;
}


.text-name {
    color: rgba(10, 13, 20, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
}

.text-email {
    color: rgba(82, 88, 102, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.ys-avatar-style {
    width: 40px;
    height: 40px;
    border-radius: 96px;
}

.ys-avatar-style-mini {
    width: 32px;
    height: 32px;
    border-radius: 96px;
}


.ys-avatar-icon-style {
    width: 30px;
    height: 30px;
    padding: 5px;
    gap: 10px;
    border-radius: 96px;
    border: 1px solid rgba(226, 228, 233, 1);
    box-shadow: 0 1px 2px 0 rgba(228, 229, 231, 0.24);
}
</style>